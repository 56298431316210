import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PhoneVerification from './pages/PhoneVerification';
import CarListing from './pages/CarListing';
import Home from './pages/Home';
import Scheduling from './pages/Scheduling';
import Profile from './pages/Profile';
import SpecialOffersPage from './pages/SpecialOffers';
import AllCars from './pages/AllCars';
import TermsAndConditions from './pages/TermsAndConditions';
import tokenManager from './utils/tokenManager';
import Favorites from './pages/Favorites';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, tokens } = useSelector((state) => state.auth);
  const location = useLocation();

  if ((!isAuthenticated || !tokens) && tokenManager.getAccessToken() ) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

// Add this component to prevent authenticated users from accessing auth pages
const PublicOnlyRoute = ({ children }) => {
  const { isAuthenticated, tokens } = useSelector((state) => state.auth);
  const location = useLocation();
  const from = location.state?.from?.pathname || '/home';

  if (isAuthenticated && tokens && tokenManager.getAccessToken() ) {
    return <Navigate to={from} replace />;
  }

  return children;
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/home" element={<Home />} />
        <Route path="/car-listing/:id" element={<CarListing />} />

        {/* Authentication Routes - Now wrapped with PublicOnlyRoute */}
        <Route
          path="/signup"
          element={
            <PublicOnlyRoute>
              <SignUp />
            </PublicOnlyRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicOnlyRoute>
              <Login />
            </PublicOnlyRoute>
          }
        />
        <Route
          path="/verify-otp"
          element={
            <PublicOnlyRoute>
              <PhoneVerification />
            </PublicOnlyRoute>
          }
        />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/scheduling"
          element={
            <PrivateRoute>
              <Scheduling />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
   <Route
          path="/favorites"
          element={
            <PrivateRoute>
              <Favorites />
            </PrivateRoute>
          }
        />
        <Route
          path="/special-offers"
          element={
            <SpecialOffersPage />
          }
        />
        <Route
          path="/all-cars"
          element={
            <AllCars />
          }
        />
        <Route
          path="/terms-conditions"
          element={
            <TermsAndConditions />
          }
        />
        {/* Default Routes */}
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </Router>
  );
};

export default App;