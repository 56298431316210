import { 
  LOGIN_SUCCESS, 
  LOGIN_FAILURE, 
  LOGOUT, 
  SIGNUP_SUCCESS, 
  SIGNUP_FAILURE 
} from '../actions/authActions';

const initialState = {
  user: null,
  isAuthenticated: false,
  error: null,
  tokens: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        tokens: action.payload.tokens,
        isAuthenticated: true,
        error: null,
      };

    case LOGIN_FAILURE:
    case SIGNUP_FAILURE:
      return {
        ...state,
        user: null,
        tokens: null,
        isAuthenticated: false,
        error: action.payload,
      };

    case LOGOUT:
      // Clear tokens from local storage on logout
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return {
        ...state,
        user: null,
        tokens: null,
        isAuthenticated: false,
        error: null,
      };

    case 'TOKEN_REFRESH':
      return {
        ...state,
        tokens: {
          ...state.tokens,
          accessToken: action.payload.accessToken
        }
      };

    case 'AUTH_ERROR':
      return {
        ...state,
        user: null,
        tokens: null,
        isAuthenticated: false,
        error: action.payload
      };

    case 'CLEAR_AUTH_ERROR':
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};

export default authReducer;