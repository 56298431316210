import api from "../utils/axiosInterceptor"

class FavoriteService {
    static LOCAL_STORAGE_KEY = "favorites";

    /** Get favorite car IDs from localStorage */
    static getLocalFavorites() {
        return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY)) || [];
    }

    /** Save favorites to localStorage */
    static saveLocalFavorites(favorites) {
        localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(favorites));
    }

    /** Check if a car is a favorite */
    static isFavorite(carId) {
        const favorites = this.getLocalFavorites();
        console.log("favorites",favorites)
        return favorites.includes(carId);
    }

    /** Toggle a car as a favorite */
    static toggleFavorite(carId, userId = null) {
        let favorites = this.getLocalFavorites();

        if (favorites.includes(carId)) {
            favorites = favorites.filter(id => id !== carId); // Remove from favorites
        } else {
            favorites.push(carId); // Add to favorites
        }

        this.saveLocalFavorites(favorites);

        // If user is logged in, sync with backend
        if (userId) {
            this.syncFavoritesWithBackend(userId);
        }
    }

    /** Sync local favorites with backend */
    static async syncFavoritesWithBackend(userId) {
        const localFavorites = this.getLocalFavorites();

        try {
            for (const itemId of localFavorites) {
                await api.post("/favorite", {userId,itemId},{ headers: { "Content-Type": "application/json" } } );
            }

            // Clear localStorage after syncing
            // localStorage.removeItem(this.LOCAL_STORAGE_KEY);
        } catch (error) {
            console.error("Failed to sync favorites:", error);
        }
    }

    /** Fetch favorites from backend */
    static async getFavoritesFromBackend(userId) {
        try {
            const response = await api.get('/favorite', { params: { "userId": userId } });

            const data = await response.json();
            return data.favorites || [];
        } catch (error) {
            console.error("Failed to fetch favorites:", error);
            return [];
        }
    }
}

export default FavoriteService;
