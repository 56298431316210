// components/pages/Home.jsx
import React from 'react';
import { Typography } from '@mui/material';

const Home = () => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Welcome Home
      </Typography>
      <Typography variant="body1">
        This is your dashboard home page where you can see an overview of your activities.
      </Typography>
    </div>
  );
};

export default Home;