import React from "react";
import { Box, Button, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

const LogoutConfirmation = ({handleClose}) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    localStorage.clear(); // Clears all local storage
    navigate("/login");
    handleClose(); // Redirects to home page
  };
  return (
    <Box
      sx={{
        maxWidth: 400,
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: "white",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h6" fontWeight="600">
        Confirm Logout
      </Typography>
      <Typography color="textSecondary">
        Are you sure you want to log out?
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
       
        <Button onClick={handleConfirm} variant="contained" color="error">
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default LogoutConfirmation;
