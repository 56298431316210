import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

const Section = styled(Box)(({ theme }) => ({
  marginBottom: "20px",
  padding: "10px 0",

}));

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ padding: "20px", backgroundColor: "#f9f9f9" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Terms and Conditions for MekinaGebeya
      </Typography>

      <Section>
        <Typography variant="h6">1. Introduction</Typography>
        <Typography variant="body1">
          Welcome to Mekina Gebeya! By accessing or using our website and Mobile
          application you agree to comply with and be bound by these Terms and
          Conditions. If you do not agree with any part of these terms, please
          do not use our website and mobile application.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">2. Definitions</Typography>
        <Typography variant="body1">
          <strong>“MekinaGebeya”:</strong> Refers to our car dealership, its
          website, Mobile App, and related services.
          <br />
          <strong>“User”:</strong> Refers to any individual or entity accessing
          our website.
          <br />
          <strong>“Vehicle Listing”:</strong> Refers to the cars or vehicles
          displayed on our website for sale.
          <br />
          <strong>“Inspected”:</strong> Refers to the cars or vehicles that
          passed physical inspection in a partner garage.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">3. Scope of Services</Typography>
        <Typography variant="body1">
          MekinaGebeya offers the following:
          <ul>
            <li>Sale of new and used vehicles.</li>
            <li>Vehicle listing services for third-party sellers.</li>
            <li>Financing options and after-sale services.</li>
          </ul>
          All services are subject to availability and applicable terms.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">4. Eligibility</Typography>
        <Typography variant="body1">
          By using our website, you confirm that you are:
          <ul>
            <li>At least 18 years old.</li>
            <li>Legally capable of entering into a binding contract.</li>
          </ul>
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">5. Vehicle Listings</Typography>
        <Typography variant="body1">
          Vehicle details, prices, and specifications provided on our website
          are for informational purposes only. Prices may be subject to change
          without prior notice. MekinaGebeya is not responsible for any
          discrepancies in third-party vehicle listings.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">6. Payments</Typography>
        <Typography variant="body1">
          All payments can’t be made through our website. Deposits or down
          payments are non-refundable unless explicitly stated. Mekina Gebeya
          reserves the right to verify payment details before completing any
          transaction.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">7. Warranties and Returns</Typography>
        <Typography variant="body1">
          Vehicles sold are subject to the manufacturer’s warranty (if
          applicable) or any warranty explicitly provided by MekinaGebeya. All
          sales are final, except as required by law or specified in the
          purchase agreement.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">8. Liability Disclaimer</Typography>
        <Typography variant="body1">
          MekinaGebeya is not responsible for:
          <ul>
            <li>
              Any inaccuracies in vehicle listings or descriptions provided by
              third-party sellers.
            </li>
            <li>
              Loss or damage resulting from unauthorized access to our website
              or data breaches.
            </li>
            <li>
              Mechanical failures or defects post-purchase unless covered by a
              warranty.
            </li>
          </ul>
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">9. User Conduct</Typography>
        <Typography variant="body1">
          Users agree not to:
          <ul>
            <li>Misuse the website in any way, including attempts to gain unauthorized access.</li>
            <li>Post false or misleading information in vehicle listings.</li>
            <li>Engage in fraudulent activities.</li>
          </ul>
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">10. Privacy Policy</Typography>
        <Typography variant="body1">
          Mekina Gebeya is committed to protecting your personal information.
          Please refer to our Privacy Policy for details on how we collect,
          use, and store your data.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">11. Governing Law</Typography>
        <Typography variant="body1">
          These Terms and Conditions are governed by and construed in
          accordance with the laws of Ethiopia. Any disputes will be resolved
          in the jurisdiction of Ethiopia.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">12. Changes to Terms and Conditions</Typography>
        <Typography variant="body1">
          MekinaGebeya reserves the right to update or modify these Terms and
          Conditions at any time without prior notice. Continued use of our
          website constitutes your acceptance of the revised terms.
        </Typography>
      </Section>

      <Section>
        <Typography variant="h6">13. Contact Information</Typography>
        <Typography variant="body1">
          For any inquiries, please contact us at:
          <br />
          Email: support@mekinagebeya.com
          <br />
          Phone: +251911683030
        </Typography>
      </Section>
    </Container>
  );
};

export default TermsAndConditions;
