import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { analytics, logEvent } from "../firebaseConfig.js";

// Function to detect WebView
const isWebView = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (
    /FBAN|FBAV|Instagram|Twitter|Snapchat|Line|wv/.test(userAgent) ||
    (userAgent.includes("Android") && userAgent.includes("wv"))
  );
};

// Function to detect browser type
const getBrowserType = () => {
  const userAgent = navigator.userAgent;

  if (isWebView()) return "WebView";
  if (userAgent.includes("Firefox")) return "Firefox";
  if (userAgent.includes("Chrome")) return "Chrome";
  if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
  if (userAgent.includes("Edge")) return "Edge";
  if (userAgent.includes("MSIE") || userAgent.includes("Trident")) return "Internet Explorer";
  return "Unknown";
};

// Function to get or generate device ID
const getStoredDeviceId = async () => {
  let deviceId = localStorage.getItem("device_id");

  if (!deviceId) {
    try {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      deviceId = result.visitorId;
      localStorage.setItem("device_id", deviceId);
    } catch (error) {
      console.error("FingerprintJS failed, using UUID:", error);
      deviceId = uuidv4();
      localStorage.setItem("device_id", deviceId);
    }
  }

  return deviceId;
};

// Function to log device info to Firebase Analytics
const logDeviceInfoToAnalytics = (deviceId, browser, platform, userAgent) => {
  logEvent(analytics, "device_info", {
    device_id: deviceId,
    browser: browser,
    platform: platform,
    user_agent: userAgent,
  });

 
};

const useDeviceInfo = () => {
  const [browserInfo, setBrowserInfo] = useState({});
  const [deviceId, setDeviceId] = useState("");

  useEffect(() => {
    (async () => {
      const browser = getBrowserType();
      const userAgent = navigator.userAgent;
      const platform = navigator.platform;

      const id = await getStoredDeviceId();
      setDeviceId(id);

      setBrowserInfo({ browser, platform, userAgent });

      // Log to Firebase Analytics
      logDeviceInfoToAnalytics(id, browser, platform, userAgent);
    })();
  }, []);

  return { browserInfo, deviceId };
};

export default useDeviceInfo;
