import React from 'react';
import styled from 'styled-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import LoadingAnimation from '../LoadingAnimation';



const StyledFrame = styled.div`
  background-color: #f8f9fa;
  padding: 20px 10px;
  @media (min-width: 768px) {F
    padding: 30px 30px;
  }
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
  @media (min-width: 700px) {
    font-size: 36px;
  }
      
  @media (min-width: 400px) {
     font-size: 24px;

  }
       @media (min-width: 900px) {
    font-size: 48px;
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
`;

const OffersContainer = styled.div`
  display: grid;
  gap: 60px;

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 2fr);
  }
`;


const ExploreMore = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 600;
  color: #011c2f;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;




// Updated styled components with enhanced aesthetics

const OfferCard = styled(Link)`
  background-color: #fff;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.12);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  text-decoration: none;
  &:hover {
    transform: translateY(-15px);
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.05) 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 300px;
  overflow: hidden;
  
  @media (min-width: 500px) {
     height: 300px;

  }

  @media (min-width: 900px) {
     height: 400px;

  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 1;
  }
`;

const OfferImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
   aspect-ratio: 0.8;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  ${OfferCard}:hover & {
    transform: scale(1.08);
  }
    
  /* For medium screens */
  @media (max-width: 1024px) {
    aspect-ratio: 0.9;
  }

  /* For small screens */
  @media (max-width: 768px) {
    aspect-ratio: 0.7;
  }
`;

const DiscountBadge = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  background: linear-gradient(135deg, #efb600 0%, #ff9d00 100%);
  color: #fff;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 18px;
  box-shadow: 0 4px 15px rgba(239, 182, 0, 0.3);
  z-index: 2;
  transform: translateY(0);
  transition: transform 0.3s ease;

  ${OfferCard}:hover & {
    transform: translateY(-3px);
  }
`;

const ContentContainer = styled.div`
  padding: 15px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #f8f9fa 100%
  );
`;

const CarTitle = styled.h3`
  font-size: 26px;
  font-weight: 800;
  color: #1a1a1a;
  margin-bottom: 12px;
  transition: color 0.3s ease;

  ${OfferCard}:hover & {
    color: #011c2f;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 5px 0;
  border-bottom: 2px dashed #eee;
`;

const Price = styled.span`
  font-size: 32px;
  font-weight: 800;
  background: linear-gradient(135deg, #efb600 0%, #ff9d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.5px;
`;

const ViewDetailsButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(135deg, #011c2f 0%, #023e6a 100%);
  color: #fff;
  padding: 16px 32px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  transform: translateY(0);
  transition: all 0.3s ease;
  box-shadow: 0 6px 15px rgba(1, 28, 47, 0.2);
  z-index: 10;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(1, 28, 47, 0.3);
    background: linear-gradient(135deg, #023e6a 0%, #011c2f 100%);
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 5px;
  position: relative;
  padding-left: 20px;
  
  &::before {
    content: '★';
    position: absolute;
    left: 0;
    color: #efb600;
  }
`;

const SpecialOffers = ({ cars, loading, isHome }) => {
  return (
    <StyledFrame>
      <SectionHeader>
        <Title>Special Offers</Title>
        <Subtitle>Discover amazing deals on our top-rated vehicles. Don't miss out on these limited-time offers!</Subtitle>
      </SectionHeader>

      {loading ? (
        <LoadingAnimation />
      ) : (
        <OffersContainer>
          {(isHome? cars.slice(0, 2):cars).map((car, index) => (

            <OfferCard key={car._id} to={`/car-listing/${car.id}`}>
              <ImageContainer>
                <OfferImage src={car.thumbnail || "/default-car-image.jpg"} alt={car.model} />
                <DiscountBadge>{car.specialOffer?.title}</DiscountBadge>
              </ImageContainer>
              <ContentContainer>
                <CarTitle>{`${car.brand?.name} ${car.model}`}</CarTitle>
                <Description>
                  Experience luxury and performance with this incredible offer. Limited time only!
                </Description>
                <PriceContainer>
                  <Price>ETB {car.price?.toLocaleString() || '00,000'}</Price>
                  {/* <OriginalPrice>${car.price.toFixed(2)}</OriginalPrice> */}
                </PriceContainer>

                <ViewDetailsButton to={`/car-listing/${car.id}`}>View Details</ViewDetailsButton>
              </ContentContainer>
            </OfferCard>
          ))}
        </OffersContainer>
      )}

      {isHome ? <ExploreMore to="/special-offers">
        Explore More Offers <ArrowForwardIcon style={{ marginLeft: '8px' }} />
      </ExploreMore> : null}
    </StyledFrame>
  );
};


export default SpecialOffers;