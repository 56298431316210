import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 shadow-lg rounded-lg" >

      <h1 className="text-2xl font-bold mb-4">Terms and Conditions for MekinaGebeya</h1>
      <p className="mb-4">
        Welcome to Mekina Gebeya! By accessing or using our website and Mobile
        application, you agree to comply with and be bound by these Terms and
        Conditions. If you do not agree with any part of these terms, please do
        not use our website and mobile application.
      </p>
      
      <h2 className="text-xl font-semibold mt-4">1. Introduction</h2>
      <p className="mb-4">
        MekinaGebeya offers vehicle sales, listings, financing options, and
        after-sale services. All services are subject to availability and
        applicable terms.
      </p>

      <h2 className="text-xl font-semibold mt-4">2. Definitions</h2>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>MekinaGebeya:</strong> Refers to our car dealership, its website, Mobile App, and related services.</li>
        <li><strong>User:</strong> Refers to any individual or entity accessing our website.</li>
        <li><strong>Vehicle Listing:</strong> Refers to the cars or vehicles displayed on our website for sale.</li>
        <li><strong>Inspected:</strong> Refers to the cars or vehicles that passed physical inspection in a partner garage.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">3. Scope of Services</h2>
      <ul className="list-disc pl-6 mb-4">
        <li>Sale of new and used vehicles.</li>
        <li>Vehicle listing services for third-party sellers.</li>
        <li>Financing options and after-sale services.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">4. Eligibility</h2>
      <p className="mb-4">By using our website, you confirm that you are at least 18 years old and legally capable of entering into a binding contract.</p>

      <h2 className="text-xl font-semibold mt-4">5. Vehicle Listings</h2>
      <p className="mb-4">Vehicle details, prices, and specifications provided on our website are for informational purposes only. Prices may change without notice.</p>

      <h2 className="text-xl font-semibold mt-4">6. Payments</h2>
      <p className="mb-4">All payments can’t be made through our website. Deposits or down payments are non-refundable unless explicitly stated.</p>

      <h2 className="text-xl font-semibold mt-4">7. Warranties and Returns</h2>
      <p className="mb-4">Vehicles sold are subject to the manufacturer’s warranty (if applicable) or any warranty explicitly provided by MekinaGebeya. All sales are final unless required by law.</p>

      <h2 className="text-xl font-semibold mt-4">8. Liability Disclaimer</h2>
      <p className="mb-4">MekinaGebeya is not responsible for inaccuracies in third-party vehicle listings, unauthorized access, or mechanical failures post-purchase unless covered by warranty.</p>

      <h2 className="text-xl font-semibold mt-4">9. User Conduct</h2>
      <ul className="list-disc pl-6 mb-4">
        <li>Users must not misuse the website or attempt unauthorized access.</li>
        <li>Posting false or misleading vehicle listings is prohibited.</li>
        <li>Fraudulent activities are strictly forbidden.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">10. Privacy Policy</h2>
      <p className="mb-4">MekinaGebeya is committed to protect your personal information. Please refer to our Privacy Policy for details.</p>

      <h2 className="text-xl font-semibold mt-4">11. Governing Law</h2>
      <p className="mb-4">These Terms and Conditions are governed by Ethiopian law, and disputes will be resolved in Ethiopia.</p>

      <h2 className="text-xl font-semibold mt-4">12. Changes to Terms and Conditions</h2>
      <p className="mb-4">MekinaGebeya reserves the right to update these Terms and Conditions at any time.</p>

      <h2 className="text-xl font-semibold mt-4">13. Contact Information</h2>
      <p className="mb-4">For inquiries, contact us at:</p>
      <p><strong>Email:</strong> support@mekinagebeya.com</p>
      <p><strong>Phone:</strong> +251911683030</p>
    </div>
  );
};

export default TermsAndConditions;
