import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import api from '../../utils/axiosInterceptor';
import { CircularProgress, Snackbar } from '@mui/material';
import tokenManager from '../../utils/tokenManager';
import LoadingAnimation from '../LoadingAnimation';

const Frame = styled.div`
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px 30px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
`;

const Header = styled.div`
  text-align: center;
  max-width: 800px;
`;

const Title = styled.h1`
  color: #011c2f;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 30px;
`;

const Subtitle = styled.p`
  color: #011c2f;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 20px;
  line-height: 22px;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

const SectionTitle = styled.h2`
  color: #011c2f;
  font-family: "SF Pro Text-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
`;

const OptionGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;
`;



const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const OptionLabel = styled.span`
  color: #011c2f;
  font-family: "SF Pro Text-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
`;

const GridSection = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 50px;
  width: 100%;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
`;



const GridItem = styled.div`
  align-items: center;
  background-color: #ffefbe;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 15px 20px;
  min-width: 150px;
  cursor: pointer; // Add this
  transition: all 0.3s ease; 
  &:hover {
    transform: translateY(-2px); // Optional: adds a slight lift effect on hover
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  }
`;

const RangeSection = styled.div`
  border: 1px solid #e0e5ec;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 50px;
  width: 100%;
`;

const RangeSlider = styled.input`
  width: 100%;
  margin: 20px 0;
`;

const RangeLabels = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  background-color: #011c2f;
  color: #ffffff;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 20px;
  border: none;
  border-radius: 8px;
  padding: 20px 40px;
  cursor: pointer;
  box-shadow: 0px 7px 10px rgba(33, 33, 33, 0.15);
`;

const Disclaimer = styled.p`
  text-align: center;
  max-width: 800px;
  margin-bottom: 30px;
`;
export const VehiclePreference = () => {
  const [preferences, setPreferences] = useState({
    fuel_type: '',
    transmission_type: '',
    car_make: '',
    car_model: '',
    mileage_range: { min: 0, max: 100000 },
    budget: { min: 0, max: 10000000 },
    city: ''
  });

  const [carTypes, setCarTypes] = useState([]); // Store car types from API
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const userId = tokenManager.getUserId();

  useEffect(() => {
    fetchUserPreferences();
    fetchCarTypes(); // Fetch car types on mount
  }, []);

  const fetchUserPreferences = async () => {
    try {
      const response = await api.get(`/users/${userId}`);
      if (response.data.vehiclePreference) {
        setPreferences(response.data.vehiclePreference);
      }
    } catch (error) {
      setSnackbarMessage('Failed to fetch preferences');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchCarTypes = async () => {
    try {
      setIsFetching(true);

      const response = await api.get('/car-type');
      setCarTypes(response.data.results); 
    } catch (error) {
      setIsFetching(false);

      console.error('Error fetching car types:', error);
    }
  };

  if (isFetching) {
    return <LoadingAnimation />;
  }

  const handleInputChange = (field, value) => {
    setPreferences(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const cleanPreferences = {};
      if (preferences.fuel_type) cleanPreferences.fuel_type = preferences.fuel_type;
      if (preferences.transmission_type) cleanPreferences.transmission_type = preferences.transmission_type;
      if (preferences.car_make) cleanPreferences.car_make = preferences.car_make;
      if (preferences.car_model) cleanPreferences.car_model = preferences.car_model;
      if (preferences.city) cleanPreferences.city = preferences.city;
      if (preferences.budget?.min !== undefined && preferences.budget?.max !== undefined) {
        cleanPreferences.budget = { min: preferences.budget.min, max: preferences.budget.max };
      }
      if (preferences.mileage_range?.min !== undefined && preferences.mileage_range?.max !== undefined) {
        cleanPreferences.mileage_range = { min: preferences.mileage_range.min, max: preferences.mileage_range.max };
      }

      await api.patch(`/users/${userId}`, { vehiclePreference: cleanPreferences });

      setSnackbarMessage('Preferences updated successfully');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message || 'Failed to update preferences');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Frame>
      <Header>
        <Title>Tell Us About Your Preferences</Title>
        <Subtitle>Choose your preferred vehicle details so we can find you your dream car faster</Subtitle>
      </Header>

      <Section>
        <SectionTitle>Select Fuel Type</SectionTitle>
        <OptionGroup>
          {['petrol', 'diesel', 'electric', 'hybrid'].map((fuel) => (
            <Option
              key={fuel}
              onClick={() => handleInputChange('fuel_type', fuel)}
            >
              <input
                type="radio"
                id={fuel}
                name="fuelType"
                color='#EFB600'
                checked={preferences.fuel_type === fuel}
                onChange={() => { }} // Empty onChange to avoid React warning
              />
              <OptionLabel>{fuel.charAt(0).toUpperCase() + fuel.slice(1)}</OptionLabel>
            </Option>
          ))}
        </OptionGroup>
      </Section>

      <Section>
        <SectionTitle>Select Transmission Type</SectionTitle>
        <OptionGroup>
          {['manual', 'automatic'].map((transmission) => (
            <Option
              key={transmission}
              onClick={() => handleInputChange('transmission_type', transmission)}
            >
              <input
                type="radio"
                id={transmission}
                color='#EFB600'
                name="transmissionType"
                checked={preferences.transmission_type === transmission}
                onChange={() => { }} // Empty onChange to avoid React warning
              />
              <OptionLabel>{transmission.charAt(0).toUpperCase() + transmission.slice(1)} Transmission</OptionLabel>
            </Option>
          ))}
        </OptionGroup>
      </Section>

      <RangeSection>
        <SectionTitle>Set your price range</SectionTitle>
        <RangeSlider
          type="range"
          min="0"
          max="10000000"
          color='#EFB600'
          value={preferences.budget?.max || 0}
          onChange={(e) => handleInputChange('budget', {
            min: 0,
            max: parseInt(e.target.value)
          })}
        />
        <RangeLabels>
          <span>0 ETB</span>
          <span>{(preferences.budget?.max || 0).toLocaleString()} ETB</span>
        </RangeLabels>
      </RangeSection>

      {/* Car Type Selection */}
      <GridSection>
        <SectionTitle>Select Your Preferred Car Type</SectionTitle>
        <Grid>
          {carTypes && carTypes.map((carType) => (
            <GridItem
              key={carType.id}
              onClick={() => handleInputChange('car_make', carType.name)}
              style={{
                backgroundColor: preferences.car_make === carType.name ? '#FFD700' : '#FFEFBE'
              }}
            >
              <span>{carType.name}</span>
            </GridItem>
          ))}
        </Grid>
      </GridSection>
      <Disclaimer>
        We will use these preferences to provide you with items within your interests. You can always change this from settings.
      </Disclaimer>
      <Button onClick={handleSubmit} disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Set Preferences'}
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Frame>
  );
};

export default VehiclePreference;


