// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBd0B_uLqVoeVDpIQ8k7DWMjhcg67Gbcyw",
    authDomain: "mekina-gebeya-3f8e1.firebaseapp.com",
    projectId: "mekina-gebeya-3f8e1",
    storageBucket: "mekina-gebeya-3f8e1.firebasestorage.app",
    messagingSenderId: "522385931425",
    appId: "1:522385931425:web:8988b6a4f808c5670044e0",
    measurementId: "G-S6GLLGBH3B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics, logEvent }