import React from "react";

export const Contour = ({ className }) => {
  return (
    <svg
      className={`contour ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.6075 9.01453C16.8916 8.54095 16.7381 7.92668 16.2645 7.64254C15.7909 7.35839 15.1767 7.51195 14.8925 7.98553L11.0515 14.3873L8.95711 12.2929C8.56658 11.9024 7.93342 11.9024 7.54289 12.2929C7.15237 12.6834 7.15237 13.3166 7.54289 13.7071L10.5429 16.7071C10.7606 16.9249 11.0666 17.0302 11.3722 16.9925C11.6778 16.9549 11.9491 16.7786 12.1075 16.5145L16.6075 9.01453Z"
        fill="white"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};