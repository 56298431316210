import React, { useState, useEffect } from "react";
import api from '../utils/axiosInterceptor';
import { Box, Container, } from '@mui/material';
import CarCard from "../components/home/CarCard";
import SpecialOffers from "../components/home/SpecialOfferCard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";



const SpecialOffersPage = ({ isProfile = false }) => {
  const [cars, setCars] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState([]);
  const [specialOffers, setSpecialOffers] = useState([]);

  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = useState({

  });

  const [availableYears, setAvailableYears] = useState([]);
  const availablePriceRanges = [
    { label: 'Under 1,000,000', value: '0-1000000' },
    { label: '1,000,000 - 2,000,000', value: '1000000-2000000' },
    { label: 'Above 2,000,000', value: '2000000-10000000' },
  ];

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const [carsResponse, specialOfferResponse] = await Promise.all([
        api.get('/cars/all', { params: { "isApproved": true } }),

        api.get('/cars/special-offers', { params: { "isApproved": true } }),

      ]);


      setCars(carsResponse.data.results);

      setSpecialOffers(specialOfferResponse.data.results);


    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      <Helmet>
        <title>Special Car Deals & Discounts | MekinaGebeya</title>
        <meta
          name="description"
          content="Get the best special offers on cars, including discounts, urgent deals, loans, and flexible payment plans. Find your next car at an unbeatable price!"
        />
        <meta
          name="keywords"
          content="car discounts, special car deals, urgent car sales, car loans, flexible car payments, best car offers"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph for Social Sharing */}
        <meta property="og:title" content="Exclusive Car Deals & Discounts | MekinaGebeya" />
        <meta
          property="og:description"
          content="Explore limited-time car discounts, urgent sales, and flexible financing options. Drive away with a great deal today!"
        />
        {/* <meta
          property="og:image"
          content="https://mekinagebeya.com/default-special-offer-thumbnail.jpg"
        /> */}
        <meta property="og:url" content="https://mekinagebeya.com/special-offers" />
      </Helmet>

      <Box sx={{ padding: 0, margin: 0, backgroundColor: 'background.default', width: "100%" }}>
        {!isProfile ? <Header /> : null}
        <Container maxWidth={false} sx={{ padding: 0, margin: 0, width: "100%" }}>
          <SpecialOffers isHome={false} cars={specialOffers} loading={loading} />
          <CarCard cars={cars} loading={loading} />
        </Container>
        {!isProfile ? <Footer /> : null}


      </Box>
    </>
  );
};


export default SpecialOffersPage;

