import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import api from '../../utils/axiosInterceptor';
import { useSnackbar } from 'notistack';
import { TextField, Button, MenuItem, Typography, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import tokenManager from '../../utils/tokenManager';
import { Delete } from 'lucide-react';
import { DeleteForeverRounded } from '@mui/icons-material';

const FormField = styled('div')({
    marginBottom: '5px',
    width: '110%',
    borderRadius: '8px'
});

const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    marginBottom: '5px',
});

const CarImage = styled('div')({
    width: '40px',
    height: '40px',
    backgroundColor: '#D9D9D9',
    borderRadius: '8px',
    backgroundImage: 'url("/home/rectangle-9-2.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
});

const TitleSection = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
});


const StatusBadge = styled('div')({
    paddingTop: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
});

const Toggle = styled('div')({
    width: '53px',
    height: '31px',
    backgroundColor: '#34A853',
    borderRadius: '133px',
    position: 'relative',
    cursor: 'pointer',
    '&::after': {
        content: '""',
        position: 'absolute',
        width: '23px',
        height: '23px',
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        top: '4px',
        left: '27px',
        transition: 'left 0.2s',
    }
});

const StatusText = styled('span')({
    color: '#FF0000',
    fontSize: '13.3px',
    fontWeight: 400,
});

const Description = styled('p')({
    color: '#464A54',
    fontSize: '14px',
    marginBottom: '20px',
});

const Title = styled(Typography)({
    color: '#011C2F',
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: '"SF Pro Text-Semibold", Helvetica'
});

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#efb600',
    color: '#ffffff',
    borderRadius: '12px',
    padding: '5px 15px',
    fontSize: '18px',
    margin:"20px",
    fontWeight: 500,
    '&:hover': {
        backgroundColor: '#d9a400',
    },
}));

const Label = styled('div')({
    color: '#011C2F',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '5px',
});

const CarForm = ({ car, initialValues, brands, carTypes, getCars, handleClose, handleDelete }) => {
    const [thumbnail, setThumbnail] = useState(null);
    const [photos, setPhotos] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedCarType, setSelectedCarType] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const formik = useFormik({
        initialValues: {
            carType: { id: '', name: '' },
            brand: { id: '', name: '' },
            model: car?.model || '',
            year: car?.year || '',
            color: car?.color || '',
            fuelType: car?.fuelType || '',
            totalWeight: car?.totalWeight || '',
            singleWeight: car?.singleWeight || '',
            numberOfSeats: car?.numberOfSeats || '',
            allowedWorkBehaviour: car?.allowedWorkBehaviour || '',
            mileage: car?.mileage || '',
            price: car?.price || '',
            condition: car?.condition || '',
            location: car?.location || '',
            transmissionType: car?.transmissionType || '',
            additionalFeatures: car?.additionalFeatures || '',
            specialOffer: {
                title: car?.specialOffer?.title || '',
                description: car?.specialOffer?.description || '',
            }
        },
        validationSchema: Yup.object({
            carType: Yup.string().required('Required'),
            brand: Yup.string().required('Required'),
            model: Yup.string().required('Required'),
            year: Yup.number().required('Required').min(1900).max(new Date().getFullYear()),
            color: Yup.string().required('Required'),
            fuelType: Yup.string().required('Required'),
            totalWeight: Yup.number(),
            singleWeight: Yup.number(),
            numberOfSeats: Yup.number(),
            allowedWorkBehaviour: Yup.string(),
            mileage: Yup.number(),
            price: Yup.number().required('Required'),
            condition: Yup.string().required('Required'),
            location: Yup.string().required('Required'),
            transmissionType: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            handleFormSubmit({ ...values, thumbnail, photos });
            handleClose();
        },
    });

    const handleFileChange = (event, type) => {
        if (type === 'thumbnail') {
            setThumbnail(event.target.files[0]);
        } else {
            setPhotos([...photos, ...event.target.files]);
        }
    };

    // Update form values when editing an existing car
    useEffect(() => {
        if (car) {
            const updatedValues = {
                ...car,
                carType: car.carType?.id || '', // Set only the id
                brand: car.brand?.id || '' // Set only the id
            };
            formik.setValues(updatedValues);
            setSelectedBrand(car.brand?.id || '')
            setSelectedCarType(car.carType?.id || '')
        }
    }, [car]);

    const handleFormSubmit = async () => {
        setIsLoading(true);
        const formValues = formik.values;
        try {
            const formData = new FormData();
            Object.keys(formValues).forEach((key) => {
                if (key === 'axelNumber') {
                    formData.append(key, JSON.stringify(formValues[key]));
                } else if (key === 'isApproved') {
                    formData.append(key, formValues[key] || false);
                } else if (key === 'allowedWorkBehaviour') {
                    if (formValues[key] !== '') {
                        formData.append(key, formValues[key] || []);
                    }
                } else if (key === 'additionalFeatures') {
                    formData.append(key, JSON.stringify(formValues[key] || []));
                } else if (key === 'checkedBy') {
                    formData.append(key, JSON.stringify(formValues[key] || {}));
                } else if (key === 'userId') {
                    formData.append(key, formValues[key].id || tokenManager.getUserId());
                } else if (key === 'brand') {
                    formData.append(key, selectedBrand);
                } else if (key === 'carType') {
                    formData.append(key, selectedCarType);
                }
                else if (key === 'specialOffer') {
                    formData.append(key, JSON.stringify(formValues[key] || {}));
                } else if (['mileage', 'totalWeight', 'singleWeight', 'motorCapacityCC', 'numberOfCylinders', 'numberOfSeats', 'price', 'year'].includes(key)) {
                    formData.append(key, formValues[key] ? Number(formValues[key]) : 0);
                } else if (key === 'approvedBy') {
                    if (formValues[key]) {
                        formData.append(key, formValues[key].id);
                    }
                } else if (!['createdAt', 'id'].includes(key)) {
                    formData.append(key, formValues[key] || '');
                }
            });
            if (thumbnail) {
                formData.append('thumbnail', thumbnail);
            } else {
                formData.delete('thumbnail');
            }
            if (photos.length > 0) {
                photos.forEach((photo) => {
                    formData.append('photos', photo);
                });
            } else {
                formData.delete('photos');
            }

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };

            if (formValues?.id) {
                try {
                    await api.patch(`/cars/${formValues.id}`, formData, config);
                    enqueueSnackbar('Car updated successfully', { variant: 'success' });
                } catch (error) {
                    enqueueSnackbar('Failed to save car: ' + (error.response?.data?.message || error.message), { variant: 'error' });
                }
            } else {
                formData.append('userId', tokenManager.getUserId());
                try {
                    await api.post('/cars', formData, config);
                    enqueueSnackbar('Car created successfully', { variant: 'success' });
                } catch (error) {
                    enqueueSnackbar('Failed to save car: ' + (error.response?.data?.message || error.message), { variant: 'error' });
                }
            }
            formik.resetForm();
            getCars();
            handleClose();
        } catch (error) {
            console.error('Error submitting form:', error.response?.data || error);
        }
        setIsLoading(false);

    };

    return (
        <div>
            <h2>{car ? 'Edit Car' : 'Add Car'}</h2>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                background: '#F0F0F0',
                border: '1px solid #D9D9D9',
                borderRadius: '20px',
                paddingLeft: '20px',
                // padding: '10px'
            }}>
                {car ? <Header>
                    {/* <CarImage /> */}
                    <TitleSection sx={{ paddingTop: "5px" }}>
                        <Title> {`${car.brand?.name}: ${car.year} ${car.model}`}</Title>

                    </TitleSection>
                    <StatusBadge>
                        <DeleteForeverRounded onClick={handleDelete} color='#FF0000' />
                        <StatusText>Delete</StatusText>
                    </StatusBadge>
                </Header> : null}

                <Description>
                    Please fill in the details below to add a new car or update the existing car information.
                    Make sure all fields are accurate before submitting.
                </Description>

                {/* Overlay for loading state */}
                {isLoading && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(255, 255, 255, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,
                    }}>
                        <CircularProgress color='#efb600' />
                    </div>
                )}
                <form onSubmit={formik.handleSubmit}>
                    <FormField>
                        <Label>Car Type</Label>
                        <TextField
                            fullWidth
                            select
                            required
                            name="carType"
                            value={formik.values.carType || ''}
                            onChange={(event) => {
                                formik.setFieldValue('carType', event.target.value);
                                setSelectedCarType(event.target.value); // Track selected item
                            }}
                            onBlur={formik.handleBlur}
                            error={formik.touched.carType && Boolean(formik.errors.carType)}
                            helperText={formik.touched.carType && formik.errors.carType}
                        >
                            {carTypes?.map((carType) => (
                                <MenuItem key={carType.id} value={carType.id}>
                                    {carType.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormField>

                    <FormField>
                        <Label>Brand</Label>
                        <TextField
                            fullWidth
                            select
                            required
                            name="brand"
                            value={formik.values.brand || ''}
                            onChange={(event) => {
                                formik.setFieldValue('brand', event.target.value);
                                setSelectedBrand(event.target.value); // Track selected item
                            }}
                            onBlur={formik.handleBlur}
                            error={formik.touched.brand && Boolean(formik.errors.brand)}
                            helperText={formik.touched.brand && formik.errors.brand}
                        >
                            {brands?.map((brand) => (
                                <MenuItem key={brand.id} value={brand.id}>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormField>

                    {['model', 'year', 'color', 'fuelType', 'condition', 'location', 'transmissionType'].map((field) => (
                        <FormField key={field}>
                            <Label>{field.replace(/([A-Z])/g, ' $1').replace(/\b\w/g, c => c.toUpperCase())}</Label>
                            <TextField
                                fullWidth
                                required
                                name={field}
                                value={formik.values[field] || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched[field] && Boolean(formik.errors[field])}
                                helperText={formik.touched[field] && formik.errors[field]}
                            />
                        </FormField>
                    ))}

                    {['totalWeight', 'singleWeight', 'numberOfSeats', 'mileage', 'price'].map((field) => (
                        <FormField key={field}>
                            <Label>{field.replace(/([A-Z])/g, ' $1').replace(/\b\w/g, c => c.toUpperCase())}</Label>
                            <TextField
                                fullWidth
                                type="number"
                                name={field}
                                value={formik.values[field] || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched[field] && Boolean(formik.errors[field])}
                                helperText={formik.touched[field] && formik.errors[field]}
                            />
                        </FormField>
                    ))}

                    <FormField>
                        <Label>Additional Features</Label>
                        <TextField
                            multiline
                            minRows={7}
                            fullWidth
                            name="additionalFeatures"
                            value={formik.values.additionalFeatures || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.additionalFeatures && Boolean(formik.errors.additionalFeatures)}
                            helperText={formik.touched.additionalFeatures && formik.errors.additionalFeatures}
                        />
                    </FormField>

                    <FormField>
                        <Label>Special Offer Title</Label>
                        <TextField
                            fullWidth
                            name="specialOffer.title"
                            value={formik.values.specialOffer?.title || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.specialOffer?.title && Boolean(formik.errors.specialOffer?.title)}
                            helperText={formik.touched.specialOffer?.title && formik.errors.specialOffer?.title}
                        />
                    </FormField>

                    <FormField>
                        <Label>Special Offer Description</Label>
                        <TextField
                            multiline
                            fullWidth
                            name="specialOffer.description"
                            value={formik.values.specialOffer?.description || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.specialOffer?.description && Boolean(formik.errors.specialOffer?.description)}
                            helperText={formik.touched.specialOffer?.description && formik.errors.specialOffer?.description}
                        />
                    </FormField>

                    <FormField>
                        <Label>Thumbnail</Label>
                        <input required={!car?.thumbnail} type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'thumbnail')} />
                    </FormField>

                    <FormField>
                        <Label>Photos</Label>
                        <input required={!car?.photos} type="file" accept="image/*" multiple onChange={(e) => handleFileChange(e, 'photos')} />
                    </FormField>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button onClick={handleClose} color="secondary">Cancel</Button>

                        <StyledButton type="submit" color="primary" variant="contained">Save</StyledButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CarForm;
