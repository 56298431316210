import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Box } from "@mui/material";


const images = [
  { small: "/home/ad11.webp", medium: "/home/ad11.webp", large: "/home/ad11.webp" },
  // { small: "/home/ad12.webp", medium: "/home/ad12.webp", large: "/home/ad12.webp" },

  { small: "/home/car2.webp", medium: "/home/car2.webp", large: "/home/car2.webp" },
  { small: "/home/car3.webp", medium: "/home/car3.webp", large: "/home/car3.webp" },
  { small: "/home/car4.webp", medium: "/home/car4.webp", large: "/home/car4.webp" },

  { small: "/home/car5.webp", medium: "/home/car5.webp", large: "/home/car5.webp" },
  { small: "/home/car6.webp", medium: "/home/car6.webp", large: "/home/car6.webp" },
];




function ImageCarousel() {
  const [interval, setInterval] = useState(11000);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let imagesToLoad = [];
    for(let i = 0; i < Math.min(3, images.length); i++){
        imagesToLoad.push(images[i].large);
    }

    let loadedCount = 0;
    const handleLoad = () => {
        loadedCount++;
        if(loadedCount === imagesToLoad.length){
            setIsLoaded(true);
        }
    }

    imagesToLoad.forEach(src => {
        const img = new Image();
        img.src = src;
        img.onload = handleLoad;
    })
  }, []);

  if (!isLoaded) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "500px" }}>
        ...
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, margin: "auto", paddingTop:"5px"}}>
      <Carousel animation="fade" interval={interval} autoPlay={true}>
        {images.map((image, i) => (
          <Paper key={i} elevation={0} sx={{transform: `translateZ(0)`,
            backfaceVisibility: "hidden",
            perspective: "1000px"
          }}>
            <picture>
              <source objectFit="cover" media="(max-width: 600px)" srcSet={image.small} type="image/webp" />
              <source objectFit="cover" media="(max-width: 1024px)" srcSet={image.medium} type="image/webp" />
              <source objectFit="cover" srcSet={image.large} type="image/webp" />
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: { xs: "200px", md: "400px", lg: "500px" },
                  objectFit: "cover",
                }}
                src={image.large}
                alt={`Slide ${i}`}
              />
            </picture>
          </Paper>
        ))}
      </Carousel>
    </Box>
  );
}

export default ImageCarousel;
