import { Box, styled, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

const BrandItem = styled(Box)`
  display: flex;
  flex-direction: column; /* Corrected */
  justify-content: center;
  align-items: center;

  
`;

const ImageWrapper = styled(Box)(({ theme, selected }) => ({
  backgroundColor: "#f0f0f0",
  borderRadius: "50%",
  height: "80px",
  width: "80px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  border: selected ? "1px solid #efb600" : "none",

  transition: "all 0.3s ease-in-out",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Default shadow

  "&:hover": {
    transform: "translateY(-5px)", // Moves up slightly
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)", // Increases shadow for lifted effect
  },
}));

const BrandImage = styled('img')({
  maxWidth: '150%',
  maxHeight: '150%',
  objectFit: 'contain',
})


const BrandName = styled(Typography)(({ theme }) => ({
  color: '#000000',
  fontSize: '24px',
  fontWeight: 400,
  letterSpacing: '-0.48px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    fontWeight: 300,

  },
}));

const BrandSection = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #ccc transparent; /* Firefox custom scrollbar */
  
  &::-webkit-scrollbar {
    height: 6px; /* Scrollbar height */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  @media (min-width: 601px) { 
    display: flex;
  }
`;

const BrandsCarousel = ({ brands, handleTypeFilter, selectedCarType }) => {
  const brandSectionRef = useRef(null);

  useEffect(() => {
    const scrollAmount = 1; // Adjust speed (smaller value = slower)
    const interval = setInterval(() => {
      if (brandSectionRef.current) {
        brandSectionRef.current.scrollLeft += scrollAmount;
        // Reset scroll position when reaching the end
        if (
          brandSectionRef.current.scrollLeft + brandSectionRef.current.clientWidth >=
          brandSectionRef.current.scrollWidth
        ) {
          brandSectionRef.current.scrollLeft = 0;
        }
      }
    }, 50); // Adjust interval time (lower = smoother movement)

    return () => clearInterval(interval);
  }, []);

  return (
    <BrandSection ref={brandSectionRef}>
      {brands.map((brand, index) => (
        <BrandItem key={index} onClick={() => handleTypeFilter(brand)}>
          <ImageWrapper selected={selectedCarType.name === brand.name}>
            <BrandImage src={brand.logo} alt={brand.name} />
          </ImageWrapper>
          <BrandName>{brand.name}</BrandName>
        </BrandItem>
      ))}
    </BrandSection>
  );
};

export default BrandsCarousel;
