import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Search } from "./carListing/Search";
import { Notifications } from "./carListing/Notifications";
import { ChatbubbleEllipses } from "./carListing/ChatbubbleEllipses";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProfilePage from "./settings/ProfilePage";
import { useSelector } from "react-redux";
import ContextMenu from "./contextMenu";
import tokenManager from "../utils/tokenManager";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#ffffff",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  position: "sticky",
  top: 0,
  zIndex: 1100,
  height: "auto",
  padding: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1),
  },
}));

const SearchBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#f0f0f0",
  border: "1px solid #e0e0e0",
  borderRadius: "10px",
  padding: "10px 20px",
  justifyContent: "space-between",
  width: "50%",
  margin: "0 40px",
  height: "58px",
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    margin: "10px 0",
  },
}));

const ProfilePhoto = styled(Box)(({ theme, ...props }) => ({
  backgroundImage: props.src
    ? `url(${props.src})`
    : `url("/listings/frame-1000005178.png")`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  borderRadius: "50%",
  height: "48px",
  width: "48px",
  border: "2px solid #fff",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const LogoLink = styled(Link)({
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
});

const Logo = styled("img")(({ theme }) => ({
  height: "58px",
  width: "auto",
  cursor: "pointer",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.05)",
  },
  [theme.breakpoints.down("sm")]: {
    height: "40px",
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));


const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#efb600',
  color: '#ffffff',
  borderRadius: '12px',
  padding: '15px 20px',
  fontSize: '18px',
  fontWeight: 700,
  '&:hover': {
    backgroundColor: '#d9a400',
  },
}));

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [profile, setProfile] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();


  const { isAuthenticated, tokens } = useSelector((state) => state.auth);

  const handleProfileClick = () => {
    
    if (tokenManager.getUser()!==null) {
      navigate('/profile');
    } else {
      navigate('/login', { state: { from: location } });
    }
  };
  const handleDrawerProfileClick = () => {
    if (tokenManager.getUser()!==null) {
      navigate('/profile');
    } else {
      navigate('/login', { state: { from: location } });
    }
    setIsDrawerOpen(false);
  };

  const handleOpenAnchor = (event) => {
    if (tokenManager.getUser()!==null) {
      setAnchorEl(event.currentTarget);
    } else {
      navigate('/login', { state: { from: location } });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const open = Boolean(anchorEl);
  const id = open ? "profile-popover" : undefined;

  const handleSearch = (searchTerm) => {
    console.log("Searching for:", searchTerm);
  };

  const openAdd = () => {
    if (tokenManager.getUser() == null) {
      navigate('/login');
    } else {
      navigate('/profile');

    }

  };

  useEffect(() => {
    if (tokenManager.getUser() !== null && (tokenManager.getProfilePicture() !== null || tokenManager.getProfilePicture() !== '')) {
      setProfile(tokenManager.getProfilePicture());
    }
  });

  return (
    <StyledAppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "stretch" : "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <LogoLink to="/home">
            <Logo src="/logo.jpg" alt="logo" />
          </LogoLink>
          {!isMobile && (
            <SearchBox>
              <Search onSearch={handleSearch} />
            </SearchBox>
          )}
          {isMobile ? (
            <StyledIconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
            </StyledIconButton>
          ) : (
            <IconWrapper>
              <StyledIconButton aria-label="notifications">
                <StyledButton onClick={openAdd}>
                  Post Car
                </StyledButton>
              </StyledIconButton>
              {/* <StyledIconButton aria-label="messages">
                <ChatbubbleEllipses />
              </StyledIconButton> */}
              <ProfilePhoto src={profile} onClick={(event) => handleOpenAnchor(event)} aria-describedby={id} />

            </IconWrapper>
          )}
        </Box>

        {isMobile && (
          <SearchBox>
            <Search onSearch={handleSearch} />
          </SearchBox>
        )}
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ProfilePage initialPage={"update-profile"} handleClose={handleClose} />
      </Popover>

      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
        <List>
          <ListItem button onClick={openAdd}>
            <ListItemText primary="Post Car" />
          </ListItem>
          <ListItem button onClick={toggleDrawer}>
            <ListItemText primary="Notifications" />
          </ListItem>
          <ListItem button onClick={toggleDrawer}>
            <ListItemText primary="Messages" />
          </ListItem>
          <ListItem button onClick={handleDrawerProfileClick}>
            <ListItemText primary="Profile" />
          </ListItem>
        </List>
      </Drawer>
      {/* <ContextMenu anchorEl={anchorEl} open={handleOpenAnchor} handleClose={handleClose} /> */}
    </StyledAppBar>
  );
};

export default Header;