import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import UpdateProfile from '../components/settings/UpdateProfile';
import VehiclePreference from '../components/settings/VehiclePreference';
import LanguagePreference from '../components/settings/LanguagePreference';
import TermsAndConditions from '../components/settings/ndConidtion';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AccessTime, ChevronRight } from '@mui/icons-material';
import tokenManager from '../utils/tokenManager';
import api from '../utils/axiosInterceptor';
import { set } from 'date-fns/esm';
import CarModal from '../components/CarModal';
import CarFormModal from '../components/CarModal';
import LoadingAnimation from '../components/LoadingAnimation';
import CarForm from '../components/carListing/carForm';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ConfirmDialog from '../components/carListing/ConfirmDialog';
import { useSnackbar } from 'notistack';




const DiscountBadge = styled('div')({
  alignSelf: 'flex-end',
  backgroundColor: '#149B09',
  borderRadius: '10px',
  padding: '10px',
  '& .text': {
    color: '#ffffff',
    fontSize: '20px',
    fontWeight: 600,
  }
});


const OfferButton = styled('button')({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  padding: '15px 20px',
  border: 'none',
  boxShadow: '0px 3px 15px rgba(0,0,0,0.15)',
  fontSize: '24px',
  fontWeight: 600,
  color: '#011C2F',
  cursor: 'pointer',
});

const NavigationSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  width: '100%',
});

const NextButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
  backgroundColor: '#011C2F',
  color: '#ffffff',
  borderRadius: '10px',
  padding: '20px',
  border: 'none',
  width: '100%',
  cursor: 'pointer',
  fontSize: '18px',
});

const Pagination = styled('div')({
  display: 'flex',
  gap: '50px',
  justifyContent: 'center',
});

const PaginationDot = styled('div')(({ active }) => ({
  width: '42.86px',
  height: '42.86px',
  borderRadius: '85.71px',
  backgroundColor: active ? '#011C2F' : '#D7D7D7',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: active ? '#ffffff' : '#000000',
  fontSize: '15.4px',
  fontWeight: 500,
}));





const ThumbnailGrid = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gap: '10px'
});



const ThumbnailImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '12px'
});





const SellerSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
});

const SellerInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '20px'
});

const SellerAvatar = styled('img')({
  width: '50px',
  height: '50px',
  borderRadius: '100px'
});

const SellerName = styled(Typography)({
  color: '#011C2F',
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: '"SF Pro Text-Medium", Helvetica'
});

const VerifiedBadge = styled(Typography)({
  color: '#149B09',
  fontSize: '20px',
  fontWeight: 500,
  fontFamily: '"SF Pro Text-Medium", Helvetica'
});



const CarTitle = styled(Typography)({
  color: '#011C2F',
  fontSize: '20px',
  fontWeight: 600,
  fontFamily: '"SF Pro Text-Semibold", Helvetica'
});

const DescriptionTitle = styled(Typography)({
  color: '#011C2F',
  fontSize: '20px',
  fontWeight: 500,
  fontFamily: '"SF Pro Text-Medium", Helvetica'
});



const ViewingButton = styled('button')({
  backgroundColor: '#011C2F',
  color: '#FFFFFF',
  borderRadius: '10px',
  padding: '15px',
  width: '100%',
  border: 'none',
  fontSize: '20px',
  fontWeight: 600,
  fontFamily: '"SF Pro Text-Semibold", Helvetica',
  boxShadow: '0px 3px 15px rgba(0,0,0,0.15)',
  cursor: 'pointer',
  margin: '10px 0'
});

const AdSection = styled('div')({
  backgroundColor: '#011C2F',
  border: '1px solid #D9D9D9',
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  gap: '20px',
  width: '100%'
});

const AdImage = styled('img')({
  width: '100px',
  height: '100px',
  borderRadius: '10px',
  objectFit: 'cover'
});

const AdContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  flex: 1
});

const AdTitle = styled(Typography)({
  color: '#FFFFFF',
  fontSize: '18px',
  fontWeight: 500,
  fontFamily: '"Poppins-Medium", Helvetica'
});

const AdText = styled(Typography)({
  color: '#FFFFFF',
  fontSize: '16px',
  letterSpacing: '-0.15px',
  lineHeight: '24px',
  fontFamily: '"SF Pro Text-Regular", Helvetica'
});







const ProductCard = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#F0F0F0',
  border: '1px solid #D9D9D9',
  borderRadius: '20px',
  padding: '20px',
  gap: '15px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '12px',

  },
}));

// const Container = styled('div')({
//   backgroundColor: '#F0F0F0',
//   border: '1px solid #D9D9D9',
//   borderRadius: '20px',
//   width: '50%',
//   padding: '20px',
//   display: 'flex',
//   flexDirection: 'column',
//   gap: '15px',
// });




// Adjust ImageContainer height
const ImageContainer = styled('img')({
  width: '100%',
  height: '250px',
  objectFit: 'cover',
  borderRadius: '10px',
});

// Modify ThumbnailWrapper size
const ThumbnailWrapper = styled('div')({
  position: 'relative',
  width: '80px',
  height: '80px',
  borderRadius: '8px',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.9,
    transform: 'scale(1.02)',
    transition: 'all 0.2s ease-in-out'
  }
});

// Modify DetailsContainer
const DetailsContainer = styled('div')({
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  flex: 1,
  width: '100%',
});

const Title = styled(Typography)({
  color: '#011C2F',
  fontSize: '24px',
  fontWeight: 600,
  fontFamily: '"SF Pro Text-Semibold", Helvetica'
});

const DescriptionText = styled(Typography)({
  color: '#454A53',
  fontSize: '14px',
  letterSpacing: '-0.15px',
  lineHeight: '20px',
  fontFamily: '"SF Pro Text-Regular", Helvetica'
});

// Modify PriceSection
const PriceSection = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#D9D9D9',
  borderRadius: '12px',
  padding: '15px', // Reduced padding
  width: '100%',
});
const OrderCard = styled('div')({
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  padding: '15px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  cursor: 'pointer',
  width: '100%',
  '&:hover': {
    backgroundColor: '#F5F5F5',
  }
});

const OrderImage = styled('img')({
  width: '80px',
  height: '80px',
  borderRadius: '10px',
  objectFit: 'cover'
});
const OrderDetails = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',

  // Adjust spacing and alignment on smaller screens
  [theme.breakpoints.down('sm')]: {
    gap: '3px',  // Reduce gap on small screens
    alignItems: 'center', // Center-align content
    textAlign: 'center'
  },

  // Adjust styling for larger screens
  [theme.breakpoints.up('md')]: {
    gap: '8px', // Increase spacing on larger screens
  }
}));


const ScheduleDate = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  color: '#666',
  fontSize: '14px'
});

const CarName = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  color: '#011C2F'
});

const CarModel = styled(Typography)({
  fontSize: '14px',
  color: '#666'
});

const Price = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  color: '#011C2F'
});

const Container = styled('div')({
  backgroundColor: '#F0F0F0',
  borderRadius: '20px',
  padding: '20px',
  width: '50%',
});

const FormField = styled('div')({
  marginBottom: '15px',
});

const Label = styled('div')({
  color: '#011C2F',
  fontSize: '16px',
  fontWeight: 600,
  marginBottom: '5px',
});

const Input = styled('input')({
  width: '100%',
  padding: '15px 20px',
  border: '1px solid #D9D9D9',
  borderRadius: '10px',
  fontSize: '18px',
  color: '#011C2F',
  '&::placeholder': {
    color: '#011C2F',
  },
  '&:focus': {
    outline: 'none',
    borderColor: '#011C2F',
  }
});

const Select = styled('div')({
  width: '100%',
  padding: '15px 20px',
  border: '1px solid #D9D9D9',
  borderRadius: '10px',
  fontSize: '13px',
  color: '#7C7C7C',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
});



const MyStore = () => {
  const [selectedPage, setSelectedPage] = useState('update-profile');
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const navigate = useNavigate();
  const [cars, setCars] = useState([]);
  const [page, setPage] = useState(1);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [carTypes, setCarTypes] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [hasMore, setHasMore] = useState(true); // Track if more pages exist
  const defaultValues = {
    carType: '',
    brand: '',
    model: '',
    year: '',
    chasisNumber: '',
    motorNumber: '',
    color: '',
    fuelType: '',
    totalWeight: '',
    singleWeight: '',
    numberOfSeats: '',
    allowedWorkBehaviour: '',
    make: '',
    mileage: '',
    price: '',
    condition: '',
    location: '',
    transmissionType: '',
    additionalFeatures: '',
    specialOffer: {
      title: '',
      description: ''
    }
  };
  const handleDelete = (id) => {
    setOpenConfirmDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/cars/${selectedCar.id}`);
      fetchInitialData();
      enqueueSnackbar('Car deleted successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to delete car: ' + error.message, { variant: 'error' });
    } finally {
      setOpenConfirmDialog(false);
      setSelectedCar(null);
    }
  };
  const handleOpen = (car = null) => {
    setSelectedCar(car);
    scrollToSection()
    // setOpen(true);
  };
  const handleClose = () => setOpen(false);


  useEffect(() => {
    fetchInitialData();
  }, [page]); // Fetch data when `page` changes

  const fetchInitialData = async () => {
    if (tokenManager.getUser() == null) {
      navigate('/login');
      return;
    }

    setUserId(tokenManager.getUserId());

    try {
      setLoading(true);
      const [carsResponse, modelsResponse, brandsResponse, carTypesResponse] = await Promise.all([
        api.get(`/cars/all`, { params: { userId: userId, page: page, } }),
        api.get('/cars/models'),
        api.get('/brand', { params: { limit: 20 } }),
        api.get('/car-type', { params: { limit: 20 } }),
      ]);

      setCars(carsResponse.data.results);
      setModels(modelsResponse.data);
      setBrands(brandsResponse.data.results);
      setCarTypes(carTypesResponse.data.results);

      // If fewer results are returned, assume no more pages
      setHasMore(carsResponse.data.results.length > 0);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === 'next' && hasMore) {
      setPage((prev) => prev + 1);
    } else if (direction === 'prev' && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  const sectionRef = useRef(null);

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) { // Adjust the threshold as needed
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Sell Your Car Easily | MekinaGebeya</title>
      </Helmet>

      <div
        style={{
          display: 'grid',
          gap: '20px',
          width: '100%',
          padding: '10px',
          paddingTop: '0',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',

          // Large screen adjustment
          '@media (min-width: 1024px)': {
            gridTemplateColumns: '3fr 2fr', // Product section takes more space than form
          }
        }}
      >
        <ProductCard>
          <Title>My Store</Title>
          <ViewingButton onClick={scrollToSection}>Add New Vehicle</ViewingButton>
          <Typography variant="h6">My Listings</Typography>

          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: '15px'
          }}>
            {cars.map((order) => (
              <OrderCard key={order.id} onClick={() => handleOpen(order)}>
                <OrderImage src={order.thumbnail} alt={order.model} />
                <OrderDetails>
                  <CarName>{order.model} {order.brand?.name}</CarName>
                  <CarModel>{order.carType?.name}</CarModel>
                </OrderDetails>
                <Price>{order.price}</Price>
                <ChevronRight />
              </OrderCard>
            ))}
          </div>

          {/* Pagination Buttons */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <button
              onClick={() => handlePageChange('prev')}
              disabled={page === 1}
              style={{ marginRight: '10px', padding: '10px' }}
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange('next')}
              disabled={!hasMore}
              style={{ padding: '10px' }}
            >
              Next
            </button>
          </div>
        </ProductCard>
        {/* Right Side: Car Forms */}
        <div ref={sectionRef} style={{ display: 'grid', gap: '10px' }}>
          <CarForm
            getCars={fetchInitialData}
            open={open}
            handleClose={handleClose}
            car={selectedCar}
            handleSubmit={() => console.log('test')}
            carTypes={carTypes}
            brands={brands}
            handleDelete={handleDelete}
            initialValues={defaultValues}
          />

        </div>
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={confirmDelete}
          title="Confirm Delete"
          content={`Are you sure you want to delete ${selectedCar?.brand?.name ?? 'Unknown Brand'}: ${selectedCar?.year ?? 'Unknown Year'} ${selectedCar?.model ?? 'Unknown Model'}`}
        />
        {showScrollToTop && (
          <button
            onClick={scrollToTop}
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              backgroundColor: '#efb600',
              width: '50px', // Adjust size as needed
              height: '50px', // Adjust size as needed
              borderRadius: '50%', // Make it circular
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              border: 'none',
              fontSize: '1.5em', // Adjust icon size
              color: 'white', // Icon color
            }}
            aria-label="Scroll to top" //Accessibility
          >
            &#8593;
          </button>
        )}
      </div>
    </>
  );
};

export default MyStore;