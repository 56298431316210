import React from "react";

export const  Contour1 = ({ className }) => {
  return (
    <svg
      className={`contour-1 ${className}`}
      fill="none"
      height="10"
      viewBox="0 0 10 10"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.91971 3.75597C7.0381 3.55865 6.97412 3.3027 6.77679 3.18431C6.57947 3.06591 6.32352 3.1299 6.20513 3.32722L4.60469 5.99462L3.73205 5.12197C3.56933 4.95925 3.30551 4.95925 3.14279 5.12197C2.98007 5.28469 2.98007 5.54851 3.14279 5.71123L4.39279 6.96123C4.48352 7.05195 4.61098 7.09582 4.73833 7.08014C4.86567 7.06447 4.97869 6.99099 5.04471 6.88097L6.91971 3.75597Z"
        fill="white"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M9.16658 4.99992C9.16658 7.30111 7.30111 9.16658 4.99992 9.16658C2.69873 9.16658 0.833252 7.30111 0.833252 4.99992C0.833252 2.69873 2.69873 0.833252 4.99992 0.833252C7.30111 0.833252 9.16658 2.69873 9.16658 4.99992ZM8.33325 4.99992C8.33325 6.84087 6.84087 8.33325 4.99992 8.33325C3.15897 8.33325 1.66659 6.84087 1.66659 4.99992C1.66659 3.15897 3.15897 1.66659 4.99992 1.66659C6.84087 1.66659 8.33325 3.15897 8.33325 4.99992Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};



export const ContourGreen = ({ className }) => {
  return (
    <svg
      className={`contour-1 ${className}`}
      fill="none"
      height="10"
      viewBox="0 0 10 10"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.91971 3.75597C7.0381 3.55865 6.97412 3.3027 6.77679 3.18431C6.57947 3.06591 6.32352 3.1299 6.20513 3.32722L4.60469 5.99462L3.73205 5.12197C3.56933 4.95925 3.30551 4.95925 3.14279 5.12197C2.98007 5.28469 2.98007 5.54851 3.14279 5.71123L4.39279 6.96123C4.48352 7.05195 4.61098 7.09582 4.73833 7.08014C4.86567 7.06447 4.97869 6.99099 5.04471 6.88097L6.91971 3.75597Z"
        fill="green"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M9.16658 4.99992C9.16658 7.30111 7.30111 9.16658 4.99992 9.16658C2.69873 9.16658 0.833252 7.30111 0.833252 4.99992C0.833252 2.69873 2.69873 0.833252 4.99992 0.833252C7.30111 0.833252 9.16658 2.69873 9.16658 4.99992ZM8.33325 4.99992C8.33325 6.84087 6.84087 8.33325 4.99992 8.33325C3.15897 8.33325 1.66659 6.84087 1.66659 4.99992C1.66659 3.15897 3.15897 1.66659 4.99992 1.66659C6.84087 1.66659 8.33325 3.15897 8.33325 4.99992Z"
        fill="green"
        fillRule="evenodd"
      />
    </svg>
  );
};
