import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Appstore } from "./carListing/Appstore";
import { Playstore } from "./carListing/Playstore";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
const FrameContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '60px', // Increased gap for better spacing
  },
}));

const ColumnWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  flex: 1, // Ensures equal width distribution
  minWidth: '200px', // Prevents columns from shrinking too much
  maxWidth: '300px', // Restricts columns from growing too wide
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}));

const FrameWrapper = styled(Box)(({ theme }) => ({
  // alignItems: 'center',
  backgroundColor: '#011c2f',
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  justifyContent: 'space-between',
  padding: '50px 20px',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    padding: '50px 30px',
  },
}));

// const FrameContent = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   width: '100%',
//   gap: '30px',
//   [theme.breakpoints.up('md')]: {
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'flex-start',
//   },
// }));

const ImageBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'url(/listings/frame-1000005268.png)',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  height: '100px',
  width: '250px',
  [theme.breakpoints.up('sm')]: {
    height: '157px',
    width: '384px',
  },
}));

// const ColumnWrapper = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   gap: '20px',
//   alignItems: 'center',
//   [theme.breakpoints.up('md')]: {
//     alignItems: 'flex-start',
//   },
// }));

const StyledText = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontFamily: 'Poppins-Regular, Helvetica',
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    fontSize: '20px',
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    gap: '50px',
  },
}));

const StyledButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  gap: '10px',
  padding: '10px 15px',
  [theme.breakpoints.up('sm')]: {
    padding: '15px 20px',
  },
}));

const LineWrapper = styled(Box)(({ theme }) => ({
  alignSelf: 'stretch',
  padding: '10px',
  width: '100%',
}));

const StyledLine = styled(Box)(({ theme }) => ({
  alignSelf: 'stretch',
  height: '1px',
  width: '100%',
  objectFit: 'cover',
  backgroundColor: '#5F6368',
}));

const Footer = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));

  return (
    <FrameWrapper>
      <FrameContent>
        <ImageBox />
        <ColumnWrapper>
          <StyledText>Contact Us</StyledText>
          <StyledText>Location:</StyledText>
          <StyledText>support@mekinagebeya.com</StyledText>
          <StyledText>Email:</StyledText>
          <StyledText>support@mekinagebeya.com
          </StyledText>
          <StyledText>Phone: </StyledText>
          <StyledText>+251911683030</StyledText>
        </ColumnWrapper>
        <ColumnWrapper>

          <Link to="/terms-conditions" style={{ textDecoration: 'none' }}><StyledText>Terms & Conditions</StyledText></Link>
          <StyledText>Support Center</StyledText>
        </ColumnWrapper>
        <ColumnWrapper>
          <a target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }} href="https://web.facebook.com/profile.php?id=61570258513498"><StyledText>Facebook</StyledText></a>
          <a target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }} href="https://www.instagram.com/mekinagebeyacom?igsh=bmxpc3I2dDdzb29p&utm_source=qr">  <StyledText>Instagram</StyledText></a>
          <a target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }} href="https://www.tiktok.com/@mekinagebeya.com?_t=ZS-8tLMA3myyn4&_r=1"><StyledText>TikTok</StyledText></a>
          <a target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }} href="https://t.me/mekinagebeyacom">  <StyledText>Telegram</StyledText></a>
          <a target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }} href="https://youtube.com/@mekinagebeya?si=9gdu_ATvfqzksiOW"><StyledText>Youtube </StyledText></a>
        </ColumnWrapper>
        <ButtonContainer>
          <StyledButton>
            <Playstore className="icon-instance-node" />
            <Typography sx={{ color: '#000000', fontSize: isMobile ? '14px' : '16px' }}>
              Get on Playstore
            </Typography>
          </StyledButton>
          <StyledButton>
            <Appstore className="icon-instance-node" />
            <Typography sx={{ color: '#000000', fontSize: isMobile ? '14px' : '16px' }}>
              Get on Appstore
            </Typography>
          </StyledButton>
        </ButtonContainer>
      </FrameContent>
      <LineWrapper>
        <StyledLine />
      </LineWrapper>
      <Typography sx={{ color: '#ffffff', fontSize: isMobile ? '12px' : '14px', textAlign: 'center' }}>
        Mekina Gebeya 2024 © All Rights Reserved
      </Typography>
    </FrameWrapper>
  );
};

export default Footer;