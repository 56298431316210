const tokenManager = {
    setTokens: (tokens) => {
      localStorage.setItem('accessToken', tokens.access.token);
      localStorage.setItem('refreshToken', tokens.refresh.token);
    },
    setUser: (user) => {
      localStorage.setItem('user', JSON.stringify(user));
     
    },
    getAccessToken: () => localStorage.getItem('accessToken'),
    getRefreshToken: () => localStorage.getItem('refreshToken'),
    getUser: () => JSON.parse(localStorage.getItem('user')),
    getUserId: () =>  JSON.parse(localStorage.getItem('user')).id??'',
    getProfilePicture:()=>JSON.parse(localStorage.getItem('user')).profilePicture??'',
    clearTokens: () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    setRememberMe: (value) => localStorage.setItem('rememberMe', value),
    getRememberMe: () => localStorage.getItem('rememberMe') === 'true',
  };
  
  export default tokenManager;