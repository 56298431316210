import api from '../utils/axiosInterceptor';

const authService = {
  signup: (userData) => api.post('/auth/register', userData),
  login: (credentials) => api.post('/auth/login', credentials),
  verifyPhone: (userId, code) => api.post(`/users/${userId}/verify-phone`, { verificationCode: code }),
  googleLogin: (idToken) => api.post('/auth/google', { idToken }),
  logout: () => api.post('/auth/logout'),
};

export default authService;