import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Box, Typography, Container, Button, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import api from '../utils/axiosInterceptor';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '8px',
  padding: '12px 0',
  fontSize: '1rem',
  fontWeight: 'bold',
  textTransform: 'none',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.25rem',
  },
  '&:hover': {
    backgroundColor: '#333',
  },
  '&:disabled': {
    backgroundColor: '#666',
    color: '#ccc',
  },
}));

const OtpInput = styled('input')(({ theme }) => ({
  width: '2.5rem',
  height: '2.5rem',
  margin: '0 0.25rem',
  fontSize: '1.25rem',
  borderRadius: '8px',
  border: '2px solid #ccc',
  textAlign: 'center',
  outline: 'none',
  [theme.breakpoints.up('sm')]: {
    width: '3rem',
    height: '3rem',
    fontSize: '1.5rem',
  },
  '&:focus': {
    borderColor: 'black',
    boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.2)',
  },
}));

const OTPVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    let timer;
    if (countdown > 0 && resendDisabled) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      setResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [countdown, resendDisabled]);

  const handleResendOTP = async () => {
    try {
      await api.post('/auth/resend-otp', { phone: location.state.phone });
      setSnackbarSeverity('success');
      setSnackbarMessage('OTP resent successfully!');
      setOpenSnackbar(true);
      setResendDisabled(true);
      setCountdown(60);
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage(error.response?.data?.message || 'Failed to resend OTP. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleVerify = async () => {
    try {
      await api.post('/auth/verify-phone', {
        phone: location.state.phone,
        otp: otp.join(''),
      });

      setSnackbarSeverity('success');
      setSnackbarMessage('Phone number verified successfully!');
      setOpenSnackbar(true);

      setTimeout(() => {
        navigate('/login');
      }, 1500);
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage(error.response?.data?.message || 'Verification failed. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleChange = useCallback(
    (element, index) => {
      if (isNaN(element.value)) return false;

      setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    },
    [otp]
  );

  return (
    <Box
      sx={{
        backgroundImage: 'url(/cover.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '16px',
            padding: { xs: '20px', sm: '40px' },
            backdropFilter: 'blur(10px)',
            width: '100%',
            maxWidth: '400px',
          }}
        >
          <Typography variant="h4" component="h1" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center', color: 'black' }}>
            Verify Your Phone Number
          </Typography>
          <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'black' }}>
            Enter the 6-digit code sent to your phone
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            {otp.map((data, index) => (
              <OtpInput
                key={index}
                value={data}
                onChange={e => handleChange(e.target, index)}
                onFocus={e => e.target.select()}
                maxLength="1"
              />
            ))}
          </Box>
          <StyledButton onClick={handleVerify} disabled={otp.join('').length !== 6}>
            Verify OTP
          </StyledButton>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link component="button" variant="body2" onClick={handleResendOTP} disabled={resendDisabled}>
              {resendDisabled ? `Resend OTP in ${countdown}s` : 'Resend OTP'}
            </Link>
          </Box>
        </Box>
      </Container>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OTPVerification;
