import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import { Contour } from "./Contour";
import FavoriteButton from '../home/FavoriteButton';
import tokenManager from '../../utils/tokenManager';
import { Share, Verified } from '@mui/icons-material';

const theme = {
  primary: "#011c2f",
  secondary: "#149b09",
  accent: "#EFB600",
  background: "#f5f5f5",
  text: "#454a53",
  white: "#ffffff",
};

const Frame12 = styled(Box)(({ theme }) => ({
  width: "50%",
  padding: "20px",
  backgroundColor: theme.white,
  borderRadius: "15px",
  boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  [theme.breakpoints.down('md')]: {
    width: "100%",
  },
}));

const StyledTypography = styled(Typography)({
  color: theme.primary,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "24px",
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: "normal",
  marginTop: "-1px",
  position: "relative",
});

const Frame14 = styled(Box)(({ theme }) => ({
  alignItems: "center",
  alignSelf: "stretch",
  backgroundColor: theme.background,
  borderRadius: "15px",
  display: "flex",
  justifyContent: "space-between",
  // paddingTop: "20px",
  paddingBottom: "10px",
  position: "relative",
  width: "100%",
  flexDirection: "row", // Default: row layout

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column", // Switch to column layout on smaller screens
    alignItems: "start", // Ensure items are centered
  },
}));


const Frame15 = styled(Box)({
  alignItems: "flex-start",
  display: "inline-flex",
  flexDirection: "column",
  position: "relative",
  paddingBottom: "5px"
});

const PriceTypography = styled(Typography)({
  color: theme.primary,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "28px",
  fontWeight: 700,
  letterSpacing: 0,
  lineHeight: "normal",
  position: "relative",
  whiteSpace: "nowrap",
  width: "fit-content",
});

const StyledButton = styled(Button)({
  alignItems: "center",
  backgroundColor: theme.accent,
  borderRadius: "10px",
  boxShadow: "0px 3px 15px #00000026",
  display: "inline-flex",
  gap: "10px",
  justifyContent: "center",
  padding: "10px 20px",
  position: "relative",
  cursor: "pointer",
  color: theme.white,
  fontWeight: "bold",
  transition: "all 0.3s ease",
  '&:hover': {
    backgroundColor: "#d9a400",
    transform: "translateY(-2px)",
  },
});



const DescriptionTitle = styled(Typography)({
  alignSelf: "stretch",
  color: theme.primary,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "20px",
  fontWeight: 500,
  position: "relative",
});

const DescriptionTypography = styled(Typography)({
  alignSelf: "stretch",
  color: theme.text,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "20px",
  fontWeight: 400,
  letterSpacing: "-0.15px",
  lineHeight: "30px",
  position: "relative",
});

const Frame12MatchingFrame18 = ({ car }) => {
  const handleShare = async () => {
    const shareData = {
      title: `${car.brand.name} - ${car.model}`,
      text: `Check out this ${car.brand.name} ${car.model} from ${car.year}! It's available for ${car.price.toLocaleString()} ETB.`,
      url: window.location.href, // Current page URL
    };

    try {
      if (navigator.share) {
        // Use Web Share API if supported
        await navigator.share(shareData);
        console.log("Shared successfully!");
      } else {
        // Fallback if Web Share API is not available
        alert(
          `Sharing is not supported in your browser. You can copy the link: ${window.location.href}`
        );
      }
    } catch (error) {
      console.error("Error sharing the page:", error);
    }
  };
  return (
    <Frame12>
      <StyledTypography>
        <Verified sx={{ color: car.isApproved ? theme.secondary : theme.accent ,fontSize: 28}} />

        {` ${car.brand && car.brand.name}: ${car.year} ${car.model} `}

      </StyledTypography>

      {/* <FavoriteButton userId={tokenManager.getUserId()} carId={car.id} /> */}

      <Frame14>
        <Frame15>
          <Typography variant="body1">Price</Typography>
          <PriceTypography>{`${car.price.toLocaleString()} ETB`}</PriceTypography>
        </Frame15>
        <StyledButton onClick={handleShare}>
          <Share />
          <Typography variant="body1">Share</Typography>
        </StyledButton>
      </Frame14>

      <DescriptionTitle>Description</DescriptionTitle>
      <DescriptionTypography>
        <ul>

          <li><strong>Brand:</strong> {car.brand && car.brand.name}</li>
          <li><strong>Model:</strong> {car.model}</li>
          <li><strong>Year:</strong> {car.year}</li>
          <li><strong>Car Type:</strong> {car.carType && car.carType.name}</li>
          <li><strong>Color:</strong> {car.color}</li>
          <li><strong>Fuel Type:</strong> {car.fuelType}</li>
          <li><strong>Transmission Type:</strong> {car.transmissionType}</li>

          <li><strong>Max Power:</strong> {car.maxPower}</li>
          <li><strong>Mileage:</strong> {car.mileage} km</li>
          <li><strong>Condition:</strong> {car.condition}</li>
          <li><strong>Location:</strong> {car.location}</li>
          <li><strong>Number of Seats:</strong> {car.numberOfSeats}</li>
          <li><strong>Motor Capacity (CC):</strong> {car.motorCapacityCC}</li>
          <li><strong>Number of Cylinders:</strong> {car.numberOfCylinders}</li>
          <li><strong>Total Weight:</strong> {car.totalWeight} kg</li>
          <li><strong>Single Weight:</strong> {car.singleWeight} kg</li>


          {car.additionalFeatures.length > 0 && (
            <li><strong>Additional Features:</strong> {car.additionalFeatures.join(", ")}</li>
          )}
        </ul>
      </DescriptionTypography>
    </Frame12>
  );

};

export default Frame12MatchingFrame18;